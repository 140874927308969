function restoreScrollPosition() {
  const layoutContainer = document.getElementById('layout-container');
  const scrollPosition = sessionStorage.getItem('layoutContainerScrollPosition');
  layoutContainer.scrollTo(0, parseInt(scrollPosition, 10), { behavior: 'smooth' });
  sessionStorage.removeItem('layoutContainerScrollPosition');
}

let totalFrames = 0;
let loadedFrames = 0;

function initializeFrameTracking() {
  totalFrames = document.querySelectorAll('turbo-frame:not([id*="modal"])').length;
  loadedFrames = 0;

  if (totalFrames === 0) {
    restoreScrollPosition();
  } else {
    document.querySelectorAll('turbo-frame:not([id*="modal"])').forEach((frame) => {
      frame.addEventListener('turbo:frame-load', () => {
        loadedFrames += 1;
        if (loadedFrames === totalFrames) {
          restoreScrollPosition();
        }
      });
    });
  }
}

document.addEventListener('turbo:load', () => {
  const scrollPosition = sessionStorage.getItem('layoutContainerScrollPosition');
  if (scrollPosition !== null) {
    initializeFrameTracking();
  }
});
