import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['collapseIcon', 'expandIcon'];

  initialize() {
    this.id = this.element.dataset.id;

    if (window.localStorage.getItem(this.id) === 'true') {
      this.toggleClasses();
    } else {
      window.localStorage.setItem(this.id, 'false');
    }
  }

  toggleExpandablePlanningAllocation(_event) {
    const isOpen = (window.localStorage.getItem(this.id) === 'false');

    if (isOpen === true) {
      window.localStorage.setItem(this.id, 'true');
    } else {
      window.localStorage.setItem(this.id, 'false');
    }

    this.toggleClasses();
  }

  toggleClasses() {
    this.collapseIconTarget.classList.toggle('hidden');
    this.expandIconTarget.classList.toggle('hidden');

    const elements = document.getElementsByClassName(`member-${this.id}`);

    Array.from(elements).forEach((element) => {
      element.classList.toggle('hidden');
    });
  }
}
