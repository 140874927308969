import resolveConfig from 'tailwindcss/resolveConfig';
import ApplicationController from './application_controller';
import tailwindConfig from '../../../tailwind.config';

const config = resolveConfig(tailwindConfig);

export default class extends ApplicationController {
  static targets = ['canvas'];

  static values = { canvasWidth: Number, percent: Number, colorType: String };

  initialize() {
    this.ctx = this.canvasTarget.getContext('2d');

    // Colors
    this.backgroundColor = config.theme.colors.grayscale['200'];
    this.setForegroundColor();

    // Calculated / fixed values
    this.canvasHeight = this.canvasWidthValue;
    this.radius = (this.canvasWidthValue - 10) / 2;
    this.centerX = this.canvasWidthValue / 2;
    this.centerY = this.canvasHeight / 2;
    this.angleStart = -Math.PI / 2;
    this.angleEnd = this.angleStart + (Math.PI * 2);
    this.strokeWidth = 5;

    this.updatePercent();
  }

  setForegroundColor() {
    switch (this.colorTypeValue) {
      case 'blocked':
        this.foregroundColor = config.theme.colors.state.danger;
        break;
      case 'next':
        this.foregroundColor = config.theme.colors.state.next;
        break;
      case 'incomplete':
        this.foregroundColor = config.theme.colors.grayscale['500'];
        break;
      case 'complete':
        this.foregroundColor = config.theme.colors.state.success;
        break;
      default:
        this.foregroundColor = config.theme.colors.primary;
        break;
    }
  }

  updatePercent() {
    this.angleValue = this.angleStart + ((this.percentValue / 100) * (Math.PI * 2));
    this.endCircleX = this.centerX + (Math.cos(this.angleValue) * this.radius);
    this.endCircleY = this.centerX + (Math.sin(this.angleValue) * this.radius);

    this.drawGraph();
  }

  drawGraph() {
    // Background gray circle
    this.ctx.beginPath();
    this.ctx.arc(this.centerX, this.centerY, this.radius, this.angleStart, this.angleEnd);
    this.ctx.lineWidth = this.strokeWidth;
    this.ctx.strokeStyle = this.backgroundColor;
    this.ctx.stroke();

    // Colored partial percent circle
    this.ctx.beginPath();
    this.ctx.arc(this.centerX, this.centerY, this.radius, this.angleStart, this.angleValue);
    this.ctx.lineWidth = this.strokeWidth;
    this.ctx.strokeStyle = this.foregroundColor;
    this.ctx.stroke();

    // End colored circle
    this.ctx.beginPath();
    this.ctx.ellipse(this.endCircleX, this.endCircleY, this.strokeWidth, this.strokeWidth, 0, 0, Math.PI * 2);
    this.ctx.fillStyle = this.foregroundColor;
    this.ctx.fill();
  }
}
