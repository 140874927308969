// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import debounced from 'debounced';

import consumer from '../channels/consumer';
import controller from './application_controller';

import Comment from './comment_controller';
import Sortable from './sortable_controller';
import Form from './form_controller';

debounced.initialize(['change', 'input']);
debounced.register(['input'], { wait: 500 });

const application = Application.start();
const context = require.context('.', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

application.register('comment', Comment);
application.register('sortable', Sortable);
application.register('form', Form);

application.consumer = consumer;
StimulusReflex.initialize(application, { controller, isolate: true });
StimulusReflex.debug = process.env.RAILS_ENV === 'development';
