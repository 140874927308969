/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    interval: String, inputPrefix: String, dayOfMonth: String, monthlyByDay: String,
  };

  static targets = ['monthlyByDay', 'monthlyByDate', 'weeklyInterval', 'monthlyInterval'];

  initialize() {
    if (this.hasMonthlyByDayTarget) {
      this.monthlyByDayTarget.classList.add('hidden');
    }
  }

  saveModal() {
    this.updateEveryFromModal();
    this.updateIntervalFromModal();
    document.getElementById('interval_selection').dispatchEvent(new Event('debounced:change'));
  }

  updateEveryFromModal() {
    const modalEvery = document.getElementById('modal_every');
    const formEvery = document.getElementById(`${this.inputPrefixValue}_every`);
    formEvery.value = modalEvery.value;
  }

  updateIntervalFromModal() {
    let intervalValue = '';

    this.clearCustomValues();

    if (document.getElementById('modal_interval').value === 'Weeks') {
      intervalValue = 'weekly';
      this.updateDaysOfWeekFromModal();
    } else if (document.getElementById('modal_interval').value === 'Months') {
      if (document.getElementById('monthly_by_date').checked) {
        intervalValue = 'monthly_by_date';
        this.updateDaysOfMonthFromModal();
      } else if (document.getElementById('monthly_by_day').checked) {
        intervalValue = 'monthly_by_day';
        this.updateMonthlyByDaysFromModal();
      }
    }

    const formInterval = document.getElementById(`${this.inputPrefixValue}_interval`);
    formInterval.value = intervalValue;
  }

  clearCustomValues() {
    const formDaysOfWeek = document.getElementById(`${this.inputPrefixValue}_days_of_week`);
    formDaysOfWeek.innerHTML = '';

    const formDaysOfMonth = document.getElementById(`${this.inputPrefixValue}_days_of_month`);
    formDaysOfMonth.innerHTML = '';

    const formMonthlyByDays = document.getElementById(`${this.inputPrefixValue}_monthly_by_days`);
    formMonthlyByDays.innerHTML = '';
  }

  updateDaysOfWeekFromModal() {
    const modalDaysOfWeek = document.getElementById('schedule_summary_schedule');
    const formDaysOfWeek = document.getElementById(`${this.inputPrefixValue}_days_of_week`);
    this.addInputFields(modalDaysOfWeek.value, `${this.inputPrefixValue}[days_of_week][]`, formDaysOfWeek);
  }

  updateDaysOfMonthFromModal() {
    const modalDaysOfMonth = document.getElementById('schedule_summary_schedule');
    const formDaysOfMonth = document.getElementById(`${this.inputPrefixValue}_days_of_month`);
    this.addInputFields(modalDaysOfMonth.value, `${this.inputPrefixValue}[days_of_month][]`, formDaysOfMonth);
  }

  updateMonthlyByDaysFromModal() {
    const modalMonthlyByDays = document.getElementById('schedule_summary_schedule');
    const formMonthlyByDays = document.getElementById(`${this.inputPrefixValue}_monthly_by_days`);
    this.addInputFields(modalMonthlyByDays.value, `${this.inputPrefixValue}[monthly_by_days][]`, formMonthlyByDays);
  }

  addInputFields(values, name, parentElement) {
    const parent = parentElement;
    let elements = '';
    if (values) {
      values.split(',').forEach((value) => {
        elements = elements.concat(`<input name="${name}" type="hidden" value="${value.trim()}">`);
      });
    }
    parent.innerHTML = elements;
  }

  toggleRadio(event) {
    const selectedValue = event.target.value;
    this.intervalValue = selectedValue;
  }

  intervalValueChanged() {
    if (this.intervalValue === 'monthly_by_day') {
      this.monthlyByDateTarget.classList.add('hidden');
      this.monthlyByDayTarget.classList.remove('hidden');
    } else if (this.intervalValue === 'monthly_by_date') {
      this.monthlyByDateTarget.classList.remove('hidden');
      this.monthlyByDayTarget.classList.add('hidden');
    }
  }

  toggleInterval(interval) {
    if (interval === 'Months') {
      this.monthlyIntervalTarget.classList.remove('hidden');
      this.weeklyIntervalTarget.classList.add('hidden');
    } else if (interval === 'Weeks') {
      this.monthlyIntervalTarget.classList.add('hidden');
      this.weeklyIntervalTarget.classList.remove('hidden');
    }
  }

  updateFrequency(event) {
    const selectedValue = event.target.value;

    if (selectedValue === 'custom') {
      this.dispatch('customFrequencySelected', { detail: { content: selectedValue } });
    } else {
      this.clearCustomValues();

      if (selectedValue === 'monthly_by_day') {
        const formMonthlyByDays = document.getElementById(`${this.inputPrefixValue}_monthly_by_days`);
        this.addInputFields(this.monthlyByDayValue, `${this.inputPrefixValue}[monthly_by_days][]`, formMonthlyByDays);
      } else if (selectedValue === 'monthly_by_date') {
        const formDaysOfMonth = document.getElementById(`${this.inputPrefixValue}_days_of_month`);
        this.addInputFields(this.dayOfMonthValue, `${this.inputPrefixValue}[days_of_month][]`, formDaysOfMonth);
      }

      const formIntervalSelection = document.querySelector('[label_text="Frequency"]');
      const formInterval = document.getElementById(`${this.inputPrefixValue}_interval`);
      formInterval.value = formIntervalSelection.value;
    }
  }

  updateInterval(event) {
    this.toggleInterval(event.target.value);
  }

  restoreOriginalFrequency() {
    const originalFrequencyElement = document.getElementById(`${this.inputPrefixValue}_interval`);
    const formInterval = document.querySelector('[label_text="Frequency"]');
    if (formInterval) {
      formInterval.value = originalFrequencyElement.value;
    }
  }
}
