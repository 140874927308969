import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  chosenDays = new Set();

  connect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const monthlyByDayCheckbox = document.getElementById('monthly_by_day');
    if (monthlyByDayCheckbox) {
      monthlyByDayCheckbox.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    this.selectDefaultDay();
  }

  disconnect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const monthlyByDayCheckbox = document.getElementById('monthly_by_day');
    if (monthlyByDayCheckbox) {
      monthlyByDayCheckbox.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }
  }

  toggleDay(event) {
    const day = event.target.dataset.scheduleMonthlyByDayParam;
    if (this.chosenDays.has(day)) {
      this.deselectDay(event, day);
    } else {
      this.selectDay(event, day);
    }
  }

  selectDay(event, day) {
    const { target } = event;
    this.chosenDays.add(day);
    target.classList.add('text-grayscale-white', 'bg-primary', 'border-primary');
    target.classList.remove('text-grayscale-black', 'border-grayscale-200');
    this.setValuesAndSubmitForm();
  }

  deselectDay(event, day) {
    const { target } = event;
    this.chosenDays.delete(day);
    target.classList.remove('text-grayscale-white', 'bg-primary', 'border-primary');
    target.classList.add('text-grayscale-black', 'border-grayscale-200');
    this.setValuesAndSubmitForm();
  }

  setValuesAndSubmitForm() {
    const scheduleSummaryForm = document.getElementById('schedule_summary_form');
    const frequencyValueInput = document.getElementById('modal_every');
    const intervalInput = document.querySelector('input[name="interval"]:checked');
    const everyDataInput = document.getElementById('schedule_summary_every');
    const intervalDataInput = document.getElementById('schedule_summary_interval');
    const scheduleDataInput = document.getElementById('schedule_summary_schedule');
    const monthlyIntervalElement = document.querySelector('[data-schedule-frequency-target="monthlyInterval"]');
    const isMonthlyIntervalVisible = monthlyIntervalElement && !monthlyIntervalElement.classList.contains('hidden');
    const monthlyByDayElement = document.querySelector('[data-schedule-frequency-target="monthlyByDay"]');
    const isMonthlyByDayVisible = monthlyByDayElement && !monthlyByDayElement.classList.contains('hidden');

    if (scheduleSummaryForm && scheduleDataInput && isMonthlyByDayVisible && isMonthlyIntervalVisible) {
      everyDataInput.value = frequencyValueInput.value;
      intervalDataInput.value = intervalInput.value;
      scheduleDataInput.value = Array.from(this.chosenDays).join(',');
      scheduleSummaryForm.requestSubmit();
    }
  }

  selectDefaultDay() {
    const preSelectedDay = document.querySelector('.monthly_by_day_default_value');
    if (preSelectedDay) {
      const day = preSelectedDay.getAttribute('data-schedule-monthly-by-day-param');
      this.chosenDays.add(day);
      this.setValuesAndSubmitForm();
    }
  }
}
